<template>
    <div class="faq">
        <div class="title">
            <h1>FAQ</h1>
            <p v-if="$i18n.locale == 'zh'">常见问题解答</p>
        </div>
        <div class="qna-list">
            <div v-for="(qna, index) in info[$i18n.locale]" :key="index" :class="[ 'qna', state[index] ? 'show' : '', $t('faq.'+info.code+'.'+qna+'.question') ? '' : 'hide' ]">
                <div class="question" @click="toggleAnswer(index)">
                    <h2>{{ $t('faq.'+info.code+'.'+qna+'.question') }}</h2>
                    <span>+</span>
                </div>
                <div class="qna-answer">
                    <vue-markdown :source="$t('faq.'+info.code+'.'+qna+'.answer')"></vue-markdown>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import VueMarkdown from 'vue-markdown'

export default {
    name: 'QuestionAndAnswer',
    props: [ 'info' ],
    data: () => {
        return {
            state: []
        }
    },
    methods: {
        toggleAnswer(index) {
            this.$set(this.state, index, !this.state[index])
        }
    },
    components: {
        VueMarkdown
    },
}
</script>

<style lang="scss" scoped>
.faq {
    display: flex;

    @media (max-width: 576px) {
        display: block;
    }
    .title {
        width: 35%;
        min-width: 200px;

        h1 {
            font-size: 48px;
            margin: 0;
            line-height: 1.35;
        }
        p {
            font-weight: bold;
            font-size: 24px;
            margin: 0;
        }
        @media (max-width: 576px) {
            width: 100%;
            margin-bottom: 25px;
        }
    }
    .qna-list {
        width: 1%;
        flex-grow: 1;

        @media (max-width: 576px) {
            width: 100%;
        }
        .qna {
            border-bottom: 4px solid rgba(#000, .08);
            padding: 22px 0;

            .question {
                display: flex;
                align-items: center;
                cursor: pointer;
                -webkit-user-select: none;
                -moz-user-select: none;

                h2 {
                    font-size: 24px;
                    width: 1%;
                    flex-grow: 1;
                    margin: 0;
                    padding-right: 15px;
                }
                span {
                    font-size: 24px;
                    display: block;
                    transition: transform .3s;
                }
                &:hover {
                    color: var(--p);
                }
            }
            &:last-child {
                border-bottom: none;
            }
            .qna-answer {
                display: none;
            }
            &.show {
                .question {
                    span {
                        transform: rotate(45deg);
                    }
                }
                .qna-answer {
                    display: block;
                }
            }
            &.hide {
                display: none;
            }
        }
    }
}
</style>

<style lang="scss">
.qna-answer {
    color: #999;
    font-size: 14px;
    line-height: 1.75;

    a {
        color: var(--p);
    }
}
</style>