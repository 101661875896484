<template>
    <div class="business-contact">
        <img draggable="false" src="@/assets/social/bwc-qrcode.jpg" alt="">
        <p>{{ $t('layout.business_contact') }}</p>
    </div>
</template>

<script>
export default {
    name: 'BusinessContact'
}
</script>

<style lang="scss">
.business-contact {
    padding: 58px 0;
    display: flex;
    align-items: center;

    img {
        width: 128px;
        margin-right: 26px;
    }
    p {
        font-size: 14px;
        font-weight: bold;
        line-height: 1.8;
        white-space: pre-wrap;
    }
}
</style>