<template>
    <div class="buy">
        <div class="container">
            <h1 class="page-title">{{ $t('page.buy.title') }}</h1>
            <div class="info-row">
                <div class="info">
                    <div class="price-tag">
                        <h2>{{ $t('page.buy.price.p1') }}</h2>
                        <p>{{ $t('page.buy.price.d1') }}</p>
                    </div>
                    <div class="price-tag">
                        <h2>{{ $t('page.buy.price.p2') }}</h2>
                        <p>{{ $t('page.buy.price.d2') }}</p>
                    </div>
                    <div class="price-tag">
                        <h2>{{ $t('page.buy.price.p3') }}</h2>
                        <p>{{ $t('page.buy.price.d3') }}</p>
                    </div>
                </div>
                <div class="info">
                    <h3>{{ $t('page.buy.discount') }}</h3>
                </div>
            </div>
            <div class="buy-sec">
                <form ref="buyForm" :action="formUrl" method="POST">
                    <div class="email item">
                        <label for="buy-form-email">{{ $t('page.buy.form.email.label') }}</label>
                        <input @input="handleEmailInput" @change="handleEmailChange" @blur="handleEmailChange" type="email" name="email" id="buy-form-email">
                        <span :class="[ 'tips', emailWrong ? 'wrong' : '' ]">{{ emailTips }}</span>
                    </div>
                    <div class="seats item-sm">
                        <label for="buy-form-seats">{{ $t('page.buy.form.seats.label') }}</label>
                        <input @input="handleSeatsInput" @change="handleSeatsChange" @blur="handleSeatsChange" v-model.number.trim="seats" type="number" name="seats" id="buy-form-seats">
                        <span class="tips">{{ seatsTips }}</span>
                    </div>
                    <div class="type item-sm">
                        <label for="buy-form-type">{{ $t('page.buy.form.type.label') }}</label>
                        <select @change="handleTypeChange" v-model="buyType" name="type" id="buy-form-type">
                            <option value="一年">{{ $t('page.buy.form.type.option.one_year') }}</option>
                            <option value="两年">{{ $t('page.buy.form.type.option.two_years') }}</option>
                            <option value="六个月">{{ $t('page.buy.form.type.option.six_months') }}</option>
                        </select>
                        <span class="tips">{{ typeTips }}</span>
                    </div>
                    <div class="coupon item">
                        <label for="buy-form-coupon">{{ $t('page.buy.form.coupon.label') }}</label>
                        <input :disabled="lockCoupon" @input="handleCouponInput" @change="handleCouponChange" @blur="handleCouponChange" v-model.lazy.trim="coupon" type="text" name="coupon" id="buy-form-coupon">
                        <span class="tips">{{ couponTips }}</span>
                    </div>
                    <div class="item">
                        <!-- 平衡布局的空元素，请勿删除 -->
                    </div>
                    <div class="spacing"></div>
                    <div class="result item">
                        <label>{{ $t('page.buy.form.result.label') }}</label>
                        <h3>{{ resultPrice }}</h3>
                    </div>
                    <div class="checkout item">
                        <label>{{ $t('page.buy.form.payment.label') }}</label>
                        <div class="btn-group">
                            <a @click="pay('alipay')" :class="emailPass ? '' : 'disabled'" href="javascript:void(0)"><i class="iconfont">&#xe610;</i> {{ $t('page.buy.form.payment.alipay') }}</a>
                            <a :class="'disabled'" href="javascript:void(0)"><i class="iconfont">&#xe60e;</i> {{ $t('page.buy.form.payment.wechat_pay') }}</a>
                            <a @click="pay('paypal')" :class="emailPass ? '' : 'disabled'" href="javascript:void(0)"><i class="iconfont">&#xe905;</i> {{ $t('page.buy.form.payment.paypal') }}</a>
                        </div>
                        <span class="tips">{{ confirmTips }}</span>
                    </div>
                </form>
                <!-- <router-link class="big-btn" to="/buy/success">预览购买成功页面</router-link> -->
            </div>
            <question-and-answer :info="faqInfo"></question-and-answer>
            <business-contact></business-contact>
        </div>
    </div>
</template>

<script>
import QuestionAndAnswer from '../components/common/QuestionAndAnswer.vue'
import BusinessContact from '../components/common/BusinessContact.vue'

import faqInfo from '../data/buy-pro-faq.json'

import 'whatwg-fetch'

const priceInfo = {
    'zh': {
        'money': '￥',
        'type': {
            '一年': 480,
            '两年': 800,
            '六个月': 240,
        }
    },
    'en': {
        'money': '$',
        'type': {
            '一年': 96,
            '两年': 160,
            '六个月': 48,
        }
    }
}

export default {
    name: 'Buy',
    data: function () {
        return {
            faqInfo,
            emailPass: false,
            emailWrong: false,
            emailTips: this.$t('page.buy.form.email.tips.normal'),
            seats: 1,
            seatsTips: this.$t('page.buy.form.seats.tips.normal'),
            buyType: '一年',
            typeTips: this.$t('page.buy.form.type.tips.info', { price: this.pricePerSeat('一年') }),
            coupon: '',
            couponTips: this.$t('page.buy.form.coupon.tips.normal'),
            cloudPrice: -1,
            lockCoupon: false,
            formUrl: '',
        }
    },
    computed: {
        localPrice() {
            const info = priceInfo[this.$i18n.locale]
            const seats = Math.floor(Math.max(Math.min(100, this.seats), 0))
            return Math.round(info.type[this.buyType] * seats)
        },
        resultPrice() {
            const info = priceInfo[this.$i18n.locale]
            if (this.coupon == '') {
                return info.money + this.localPrice
            } else {
                if (this.cloudPrice == -1) {
                    return this.$t('page.buy.form.result.tips.computing')
                } else {
                    return info.money + this.cloudPrice
                }
            }
        },
        confirmTips() {
            return this.emailPass ? this.$t('page.buy.form.payment.tips.email_pass') : this.$t('page.buy.form.payment.tips.email_wrong')
        }
    },
    methods: {
        handleEmailInput() {
            this.emailTips = this.$t('page.buy.form.email.tips.normal')
            this.emailWrong = false
        },
        handleEmailChange(e) {
            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            this.emailPass = re.test(String(e.target.value).toLowerCase())
            this.emailWrong = !this.emailPass
            if (this.emailPass) {
                this.emailTips = this.$t('page.buy.form.email.tips.pass')
            } else {
                this.emailTips = this.$t('page.buy.form.email.tips.wrong')
            }
        },
        handleSeatsInput() {
            this.seatsTips = this.$t('page.buy.form.seats.tips.inputting')
        },
        handleSeatsChange(e) {
            let inputVal = (parseInt(e.target.value) || 1)
            inputVal = Math.min(Math.max(inputVal, 1), 100)
            this.seats = inputVal
            this.seatsTips = inputVal >= 10 ? this.$t('page.buy.form.seats.tips.discount2') : (inputVal >= 5 ? this.$t('page.buy.form.seats.tips.discount1') : this.$t('page.buy.form.seats.tips.normal'))

            if (this.coupon != '') {
                this.handleCouponChange({
                    target: {
                        value: this.coupon
                    }
                })
            }
        },
        pricePerSeat(type) {
            return (type == '一年' ? this.$t('page.buy.price.p2') : (type == '两年' ? this.$t('page.buy.price.p3') : this.$t('page.buy.price.p1')))
        },
        handleTypeChange(e) {
            this.typeTips = this.$t('page.buy.form.type.tips.info', { price: this.pricePerSeat(e.target.value) })
            if (this.coupon != '') {
                this.handleCouponChange({
                    target: {
                        value: this.coupon
                    }
                })
            }
        },
        pay(type) {
            if (this.emailPass) {
                if (type == 'alipay') {
                    this.formUrl = 'https://jk.fairygui.com/pay/pay.php'
                } else {
                    this.formUrl = 'https://jk.fairygui.com/pay/paypal.php'
                }
                
                this.$nextTick(() => {
                    this.$refs.buyForm.submit()
                })
            }
        },
        handleCouponInput() {
            this.couponTips = this.$t('page.buy.form.coupon.tips.normal')
        },
        async handleCouponChange(e) {
            const val = e.target.value

            this.cloudPrice = -1

            if (val == '') {
                this.couponTips = this.$t('page.buy.form.coupon.tips.normal')
            } else {
                this.couponTips = this.$t('page.buy.form.coupon.tips.normal')
                this.lockCoupon = true

                const sendBody = new FormData()
                sendBody.append('seats', this.seats)
                sendBody.append('coupon', this.coupon)
                sendBody.append('type', this.buyType)
                sendBody.append('channel', this.$i18n.locale == 'en' ? 1 : 0)

                try {
                    const cloudPrice = await window.fetch('https://jk.fairygui.com/pay/getprice.php', {
                        method: 'POST',
                        body: sendBody
                    })
                    const priceText = await cloudPrice.text()
                    const price = parseInt(priceText)
                    this.cloudPrice = price

                    if (price == this.localPrice) {
                        this.couponTips = this.$t('page.buy.form.coupon.tips.normal')
                    } else {
                        this.couponTips = this.$t('page.buy.form.coupon.tips.normal')//this.$t('page.buy.form.coupon.tips.valid', { discount: (Math.max(Math.floor((1 - price / this.localPrice) * 100), 1) + '%') })
                    }
                } catch(e) {
                    console.log(e)
                    this.couponTips = this.$t('page.buy.form.coupon.tips.normal')
                    this.cloudPrice = this.localPrice
                }

                this.lockCoupon = false
            }
        }
    },
    components: {
        BusinessContact,
        QuestionAndAnswer,
    }
}
</script>

<style lang="scss" scoped>
.buy {
    background: #000;
    color: #fff;

    .container:first-child {
        background: url(../assets/decoration/pop-black.svg) no-repeat;
        background-size: 100% auto;
        background-position: center top -50px;
    }

    .faq {
        padding: 70px 0;
    }
    .faq, .business-contact {
        border-top: 4px solid #707070;
    }

    .page-title {
        font-size: 48px;
        line-height: 1;
        margin: 0;
        margin-bottom: 25px;
        padding-top: 80px;
    }
    .info-row {
        display: flex;
        margin-bottom: 56px;

        .info {
            margin-right: 16px;
            border-radius: 8px;
            background: rgba(#fff, .1);
            width: 50%;
            flex-grow: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 20px 15px;
            backdrop-filter: blur(10px);

            &:last-child {
                margin-right: 0;
            }
            .price-tag {
                text-align: center;
                width: 29%;

                h2 {
                    margin: 0;
                    font-size: 36px;
                    line-height: 1;
                }
                p {
                    margin: 0;
                    margin-top: 3px;
                    font-size: 13px;
                    opacity: .7;
                }
            }
            h3 {
                margin: 0;
                white-space: pre-wrap;
            }
        }
        @media (max-width: 768px) {
            flex-direction: column;

            .info {
                width: 100%;
                margin-bottom: 10px;
            }
        }
        @media (max-width: 1024px) {
            .info {
                .price-tag {
                    h2 {
                        font-size: 28px;
                    }
                }
            }
        }
        @media (max-width: 576px) {
            .info {
                .price-tag {
                    h2 {
                        font-size: 21px;
                    }
                }
            }
        }
    }
}

.in-progress {
    padding: 20px 0 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.buy-sec {
    padding-bottom: 60px;

    form {
        display: flex;
        flex-wrap: wrap;
        width: calc(100% + 16px);
        margin-left: calc(-8px);

        .item, .item-sm {
            padding: 0 8px 25px;

            label {
                font-size: 18px;
                font-weight: bold;
                margin-bottom: 10px;
                display: block;
            }
            input, select {
                width: 100%;
                font-size: 16px;
                padding: 8px 14px;
                border-radius: 6px;
                border: none;
                outline: none;
                border: 2px solid transparent;

                &:focus {
                    border-color: var(--p);
                }
            }
            .tips {
                font-size: 13px;
                display: block;
                margin-top: 6px;
                color: rgb(194, 194, 194);

                &.wrong {
                    color: rgb(238, 58, 58);
                    font-weight: bold;
                }
            }
        }
        .item {
            width: 50%;

            @media (max-width: 768px) {
                width: 100%;
            }
        }
        .item-sm {
            width: 25%;

            @media (max-width: 768px) {
                width: 50%;
            }
            @media (max-width: 576px) {
                width: 100%;
            }
        }
        .result {
            h3 {
                font-size: 38px;
                margin: 0;
                line-height: 1;
            }
        }
        .btn-group {
            display: flex;
            flex-wrap: wrap;

            a {
                display: flex;
                align-items: center;
                width: 1%;
                flex-grow: 1;
                margin-right: 10px;
                border-radius: 6px;
                background: var(--p);
                color: #fff;
                font-weight: bold;
                padding: 8px 14px;
                text-decoration: none;

                .iconfont {
                    font-weight: normal;
                    font-size: 18px;
                    margin-right: 10px;
                }
                &:last-child {
                    margin-right: 0;
                }
                &:hover {
                    background: #ffae4b;
                }
                &:active {
                    background: #bb6f11;
                }
                @media (max-width: 576px) {
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    padding: 12px 8px;
                    text-align: center;
                    line-height: 1.2;

                    .iconfont {
                        margin-right: 0;
                        font-size: 28px;
                        margin-bottom: 6px;
                    }
                }
                &.disabled {
                    cursor: default;
                    background: rgb(151, 151, 151);
                }
            }
        }
        .spacing {
            width: 100%;
            height: 25px;
        }
    }
}
</style>

<style lang="scss">
.buy {
    .faq {
        .qna {
            border-bottom: 4px solid rgba(#fff, .3) !important;
            &:last-child {
                border-bottom: none !important;
            }
        }
        .qna-answer {
            color: #B8B8B8;
        }
    }
}
</style>